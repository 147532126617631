import React from "react";
import { Helmet } from "react-helmet";

// Internal components
import Heading from "components/layout/Heading";
import Container from "react-bootstrap/Container";
import Social from "components/social/Social";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Icons
import { FaMobileAlt, FaRegEnvelope } from "react-icons/fa";

const Kontakt = (props) => {
    return (
        <>
            {/* <Bg /> */}
            <Helmet>
                <title>LDS > Kontakt</title>
            </Helmet>
            <div>
                <Container>
                    <div className="py-5 pl-sm-5 mx-auto">
                        <span className="text-left">
                            <Heading h1="Kontakt" className="text-center" />
                            <Row>
                                <Col xs={12} className="text-center">
                                    <span className="h5 text-left d-inline-block">
                                        <p>
                                            <FaMobileAlt className="mr-3" />
                                            <a href="tel:93856717">
                                                +47 938 567 17
                                            </a>
                                            <br />
                                        </p>
                                        <p>
                                            <FaRegEnvelope className="mr-3" />
                                            <a href="mailto:post@lasse.media">
                                                post@lasse.media
                                            </a>
                                        </p>
                                        <div className="mt-5 pt-5">
                                            <Social />
                                        </div>
                                    </span>
                                </Col>
                            </Row>
                        </span>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Kontakt;
