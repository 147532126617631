import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { IoMdSearch } from "react-icons/io";

function Search({ handleSearch }) {
    return (
        <InputGroup className="search">
            {/* <Form.Label>Search:</Form.Label> */}
            <FormControl
                className="w-100 rounded pl-5"
                type="search"
                placeholder="Search by name..."
                onChange={(event) => handleSearch(event)}
            />
            <IoMdSearch
                size="2rem"
                className="position-absolute ml-2"
                style={{ left: 0, top: "50%", transform: "translateY(-50%)" }}
            />
        </InputGroup>
    );
}

export default Search;
