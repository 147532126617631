import React from "react";
import { NavLink } from "react-router-dom";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Button from "react-bootstrap/Button";

// Internal components
import I from "components/layout/icons/IconContainer";
import Social from "components/social/Social";
import Author from "components/info/Author";

// Icons
import { FaEye, FaMobileAlt } from "react-icons/fa";

const NavBottom = ({ className, home, color }) => {
    return (
        <Container
            className={`container-nav ${className}`}
            style={{ paddingBottom: "10rem" }}
        >
            {home ? (
                <Navbar fixed="bottom" className="m-0 px-0 navBottom d-md-none">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <NavLink to="/portfolio" as="div">
                                <Button variant="primary" className="mb-2">
                                    <span>Åpne portfolio</span>
                                    <I x="-0.1rem" className="ml-3">
                                        <FaEye />
                                    </I>
                                </Button>
                            </NavLink>
                            {/* <NavLink
                                to="/kontakt"
                                className="p-0 m-0 ml-4 d-none d-sm-block"
                            >
                                <Button
                                    variant="outline-primary"
                                    className="mb-2"
                                >
                                    <span>Ta kontakt</span>
                                    <I x="-0.1rem" className="ml-3">
                                        <FaMobileAlt />
                                    </I>
                                </Button>
                            </NavLink> */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            ) : (
                <div className="pb-5 mb-5 pb-lg-0 mb-lg-0">
                    <Row className="mb-5 pb-5 pb-lg-0 mb-lg-0">
                        <Col xl={5} className="d-none d-xl-flex">
                            <Author />
                        </Col>
                        <Col xl={4} lg={6}>
                            <Social
                                className="mx-auto text-center"
                                color={color}
                            />
                        </Col>
                        <Col xs={12} lg={6} xl={3} className="text-center">
                            <NavLink to="/kontakt" className="p-0 m-0">
                                <Button
                                    variant="secondary"
                                    className="px-2 px-sm-3 mb-2 mt-5 mt-lg-0"
                                >
                                    <span>Ta kontakt</span>
                                    <I x="-0.1rem" className="ml-5">
                                        <FaMobileAlt />
                                    </I>
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </div>
            )}
        </Container>
    );
};

export default NavBottom;
