import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// External components
import KeyboardEventHandler from "react-keyboard-event-handler";
import ScrollToTop from "react-router-scroll-top";

// Internal components
import Preloader from "components/layout/Preloader";
import Back from "components/nav/Back";
import ScrollTop from "components/nav/ScrollTop";
import NavTop from "components/nav/NavTop";
import Footer from "components/Footer"; // SASS

// VIEWS
import Home from "views/Home";
import Portfolio from "views/Portfolio";
import Kontakt from "views/Kontakt";
import About from "views/About";
import PortfolioSpecific from "views/PortfolioSpecific";
// const Home = lazy(() => import("views/Home"));
// const Portfolio = lazy(() => import("views/Portfolio"));
// const Kontakt = lazy(() => import("views/Kontakt"));
// const About = lazy(() => import("views/About"));
// const PortfolioSpecific = lazy(() => import("views/PortfolioSpecific"));

const App = (props) => {
    return (
        <>
            <Router>
                <ScrollToTop>
                    <Preloader />
                    <Route
                        render={({ location, history }) => (
                            <>
                                <KeyboardEventHandler
                                    handleKeys={["alt + shift + E"]}
                                    onKeyEvent={(key, e) =>
                                        window.open(
                                            "http://cms.lasse.media/",
                                            "_blank"
                                        )
                                    }
                                />
                                <KeyboardEventHandler
                                    handleKeys={["shift + alt + g"]}
                                    onKeyEvent={(key, e) =>
                                        window.open(
                                            "https://github.com/lassespilling/lassemedia_cra",
                                            "_blank"
                                        )
                                    }
                                />
                                <KeyboardEventHandler
                                    handleKeys={["shift + alt + k"]}
                                    onKeyEvent={(key, e) =>
                                        history.push("/kontakt")
                                    }
                                />
                                <header>
                                    <Back
                                        sticky={true}
                                        variant="primary"
                                        location={location}
                                    />
                                    <ScrollTop location={location} />
                                    <NavTop
                                        home={
                                            location.pathname === "/"
                                                ? true
                                                : false
                                        }
                                    />
                                </header>
                                <Switch>
                                    <Route path="/" exact component={Home} />
                                    <Route
                                        path="/kontakt"
                                        component={Kontakt}
                                        exact
                                    />
                                    <Route
                                        path="/about"
                                        component={About}
                                        exact
                                    />

                                    <Route
                                        path="/portfolio"
                                        component={Portfolio}
                                        exact
                                    />

                                    <Route
                                        path="/portfolio/:slug"
                                        component={PortfolioSpecific}
                                        exact
                                    />
                                </Switch>
                                <Footer location={location.pathname} />
                            </>
                        )}
                    />
                </ScrollToTop>
            </Router>
        </>
    );
};

export default App;
