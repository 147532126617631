import React from "react";

const Preloader = () => {
    return (
        <>
            <div className="d-flex align-items-center preloader">
                {/* <svg
                    id="logo"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.7 97.4"
                >
                    <path
                        d="M93.4,78.6c2,11.8-11.3,18.4-20.9,17.7s-19-4.5-26.2-11c-9.3-7.6-9.8-26-23.4-23.1L17.1,83.6c5.9.7,12.6-.2,16.7-4.5a42.6,42.6,0,0,0,7.6,10.4C32.1,98.4,12.1,96,0,96.3L11.7,52l.5-2H30.7a15.1,15.1,0,0,1,11.6,6.2c4.3,6.9,9.6,19.2,16.1,23.5,2.4,2,9.9,6.8,15.4,6.4,3.8.1,6.4-1.4,4.9-4.4-.5-1.1-2.2-1.6-2.4-1.7-5.7-2.1-7.4-2.4-10.3-3.8C56.9,72,55.7,62.3,62,55.3h0c8.1-9.1,28.4-6.9,36.4.5L90.8,65c-3.8-2.4-10.7-4.9-14.2-4.7s-5.2,2.7-3.9,4.5,4.1,1.6,9.1,3.9S92.5,74.8,93.4,78.6Z"
                        transform="translate(0.7 0.5)"
                    />
                    <path
                        d="M53.7,62.5c-1-1.7-6.3-10.8-7.1-12.3a17.7,17.7,0,0,0-15-8.5H1L12,0H25.9L18,30.3H29.5C50.7,29.7,55.5,48.2,53.7,62.5Z"
                        transform="translate(0.7 0.5)"
                    />
                </svg> */}
                <div className="preloader__img"></div>
            </div>
        </>
    );
};

export default Preloader;
