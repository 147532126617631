import React from "react";
import { Helmet } from "react-helmet";

// Bootstrap
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

// Internal components
import Emoji from "components/layout/Emoji";
import Heading from "components/layout/Heading";
import { Sanity } from "../services/sanity.service";

// Icons
import { FiUserCheck } from "react-icons/fi";

const About = () => {
    const { about } = React.useContext(Sanity);
    return (
        <>
            <Helmet>
                <title>LDS > About</title>
            </Helmet>
            <Container>
                <main
                    className="px-5 rounded-2"
                    // style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
                >
                    <Row>
                        <Heading>
                            <h1>
                                <FiUserCheck className="mr-4" />
                                Hei!
                            </h1>
                            <h2 className="mb-5">
                                Så hyggelig at du tok turen innom{" "}
                                <Emoji symbol="😁" label="sunglasses" />
                            </h2>
                        </Heading>
                        <img
                            src={
                                (about.portrait && about.portrait.asset.url) ||
                                ""
                            }
                            width="150"
                            height="150"
                            alt="profile"
                            className="rounded-circle mr-sm-5 mb-5"
                        />
                        <div className="paragraph">
                            <p className="h5">
                                Jeg er en såkalt{" "}
                                <b>
                                    <i>grafisk designer</i>
                                </b>{" "}
                                og{" "}
                                <b>
                                    <i>frontend utvikler.</i>
                                </b>{" "}
                                <br />
                                Det er i grunn bare noen fancy ord for at jeg
                                lager nettsider, plakater, logoer, brosjyrer ++.{" "}
                                <br />
                                <br />
                                La oss bare kalle meg{" "}
                                <b>
                                    <i>designer</i>
                                </b>{" "}
                                for nå.
                                <br />
                            </p>
                            <p>
                                Som designer brenner jeg for å{" "}
                                <i>forenkle, forsterke og fornye</i>. Det er
                                rett og slett vakkert når design fungerer. Som
                                en god spillefilm av ypperste klasse, der du
                                sitter på kanten av kinosete uten en eneste
                                tanke om fiktivitet.
                            </p>
                            <p>
                                Brukervennlighet er fundamentet, men
                                brukeropplevelsen er selve byggverket.
                            </p>
                            <p>
                                Ta gjerne en titt på porteføljen min, eller ta
                                kontakt hvis du lurer på noe.
                            </p>
                            <pre className="mt-5">
                                <i>Mvh Lasse D. Spilling</i>
                            </pre>
                        </div>
                    </Row>
                </main>
            </Container>
        </>
    );
};

export default About;
