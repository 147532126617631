// Import React
import React, { createContext, useState, useEffect } from 'react';

// Fetch all projects from Sanity
// Edit this data at: https://lassemedia.sanity.studio/dashboard

export const fetchSanity = () => {
  return fetch(
    // 'https://23x9eh58.api.sanity.io/v1/graphql/production/default',
    'https://a1dmdtga.api.sanity.io/v2023-08-01/graphql/production/default',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query: `
# Write your query or mutation here
{
                        allProjects {
                            slug {
                                current
                            }
                            category {
                                category
                            }
                            shortdescription
                            contentCreated
                            client
                            clientLogos {
                                asset {
                                    url
                                    extension
                                }
                            }
                            title
                            date
                            textColor {
                                hex
                                rgb {
                                    r
                                    g
                                    b
                                }
                            }
                            bgColor {
                                hex
                                rgb {
                                    r
                                    g
                                    b
                                }
                            }
                            timeSpan
                            tech
                            description
                            goals
                            learned
                            brief
                            type
                            teamSize
                            liveurl
                            videos {
                                _key
                                _type
                                asset {
                                    status
                                    assetId
                                    playbackId
                                }
                            }
                            logo {
                                asset {
                                    url
                                    extension
                                }
                            }
                            images {
                                asset {
                                    url
                                    extension
                                }
                            }
                            thumb {
                                asset {
                                    url
                                    extension
                                }
                            }
                            pdf {
                                asset {
                                    url
                                }
                            }
                            carousel {
                                asset {
                                    url
                                    extension
                                }
                            }
                        }
                        allCategories {
                            category
                        }
                    }`,
      }),
    },
  )
    .then((r) => r.json())
    .then((d) => d.data);
};
export const Sanity = createContext();

// Context provider is passed to any component requiring the context
export const SanityProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetchSanity().then((d) => setProjects(d.allProjects));
    fetchSanity().then((d) => setCategories(d.allCategories));
    // fetchSanity().then((d) => setAbout(d.allAbout[0]));
  }, []);
  return (
    <Sanity.Provider
      value={{
        projects,
        categories,
        about,
      }}>
      {children}
    </Sanity.Provider>
  );
};

// If only fetching stuff needed for cards
// export const fetchProjectThumbs = () => {
//     return fetch(
//         "https://23x9eh58.api.sanity.io/v1/graphql/production/default",
//         {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//             },
//             body: JSON.stringify({
//                 query:
//                     "{ allProjects { slug {current} title date textColor { hex } bgColor { hex } timeSpan tech type description liveurl carousel { asset { url extension } } } }",
//             }),
//         }
//     )
//         .then((r) => r.json())
//         .then((d) => d.data.allProjects);
// };

// If only fetching specific project
// export const fetchProject = (slug) => {
//     return fetch(
//         "https://23x9eh58.api.sanity.io/v1/graphql/production/default",
//         {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//             },
//             body: JSON.stringify({
//                 query: `{ allProjects(where: {slug: {current: {eq:"${slug}"}}}) { title date textColor { hex } bgColor { hex } timeSpan tech description goals slug { current } learned brief type teamSize liveurl logo { asset {url extension} } images { asset { extension url} } carousel { asset { extension url } } } }`,
//             }),
//         }
//     )
//         .then((r) => r.json())
//         .then((d) => d.data.allProjects);
// };
