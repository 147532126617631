import React from "react";
import { primaryColor } from "sass/partials/_variables.scss";
import "sass/components/_logo.scss";

const Logo = ({ color, size, className }) => {
    // eslint-disable-next-line no-unused-expressions
    color === undefined ? (color = primaryColor) : null;
    // eslint-disable-next-line no-unused-expressions
    size === undefined ? (size = "100%") : null;

    return (
        <svg
            viewBox="0 0 98.4 96.4"
            xmlns="http://www.w3.org/2000/svg"
            className={`logo d-inline-block mx-auto ${className}`}
            width={size}
            fill={color}
        >
            <g>
                <path d="m93.4 78.6c2 11.8-11.3 18.4-20.9 17.7-10.5.1-19-4.5-26.2-11-9.3-7.6-9.8-26-23.4-23.1l-5.8 21.4c5.9.7 12.6-.2 16.7-4.5 1.7 3.2 4 6.8 7.6 10.4-9.3 8.9-29.3 6.5-41.4 6.8l11.7-44.3.5-2h18.5c4.4 0 8.8 2.5 11.6 6.2 4.3 6.9 9.6 19.2 16.1 23.5 2.4 2 9.9 6.8 15.4 6.4 3.8.1 6.4-1.4 4.9-4.4-.5-1.1-2.2-1.6-2.4-1.7-5.7-2.1-7.4-2.4-10.3-3.8-9.1-4.2-10.3-13.9-4-20.9 8.1-9.1 28.4-6.9 36.4.5l-7.6 9.2c-3.8-2.4-10.7-4.9-14.2-4.7-4.8.3-5.2 2.7-3.9 4.5 2.5 2.2 4.1 1.6 9.1 3.9 6.4 2.1 10.7 6.1 11.6 9.9z" />
                <path d="m53.7 62.5c-1-1.7-6.3-10.8-7.1-12.3-3.3-5.3-8.9-8.5-15-8.5h-30.6l11-41.7h13.9l-7.9 30.3h11.5c21.2-.6 26 17.9 24.2 32.2z" />
            </g>
        </svg>
    );
};

export default Logo;
