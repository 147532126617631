import React from "react";
import { NavLink } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

// Bootstrap
import { Row, Col, Button, Badge, Container } from "react-bootstrap";

// Internal components
import CheckTech from "components/CheckTech";
import getTheme from "functions/theme";

// External components
import SanityMuxPlayer from "sanity-mux-player";
import Masonry from "react-masonry-css";

// Styling
import { borderWidth } from "sass/partials/_variables.scss";

// Icons
// React icons: https://react-icons.netlify.com/#/search
import { IoMdArrowRoundForward, IoMdArrowRoundUp } from "react-icons/io";
import { TiDocument } from "react-icons/ti";
import {
    GiArcheryTarget,
    GiTrophyCup,
    GiGears,
    GiPencilBrush,
} from "react-icons/gi";
import { FaQuoteRight, FaSuitcase } from "react-icons/fa";
import { FiImage, FiVideo } from "react-icons/fi";
import I from "components/layout/icons/IconContainer";
import SVG from "react-inlinesvg";

const PortfolioItemDetails = ({ item, expanded }) => {
    const [mobile, setMobile] = React.useState(false);

    React.useEffect(() => {
        if (window.innerWidth < 800) {
            setMobile(true);
        }
    }, [mobile, setMobile]);

    let theme = getTheme(item);
    if (item.teamSize) {
        if (item.teamSize === 1) {
            item.teamSize = "just me";
        }
    }
    const darken = (item) => {
        const r = item.bgColor.rgb.r;
        const g = item.bgColor.rgb.g;
        const b = item.bgColor.rgb.b;
        if (r + g + b > 300) {
            return "darken";
        }
    };
    const separator = (bWidth = "1px") => {
        return (
            <div
                className="w-100"
                style={Object.assign(theme.border.top, theme.border.primary, {
                    borderWidth: bWidth || borderWidth,
                })}
            />
        );
    };
    const breakpointColumnsObj = {
        default: 1,
        2000: 2,
        1500: 2,
        1200: 2,
        992: 2,
        768: 1,
        670: 1,
    };
    return (
        <>
            <header>
                <h1 className="h4 w-100 text-center py-3">
                    <NavLink to="/portfolio">Portfolio</NavLink>{" "}
                    <span className="mx-3">></span> {item.title}
                </h1>
            </header>
            <main
                className={`p-0 portfolioItemDetails overflow-hidden ${darken(
                    item
                )}`}
                style={theme.bg.secondary}
            >
                <section>
                    <article>
                        {/* Header image */}
                        {item.logo ? (
                            <section>
                                <Row
                                    style={theme.color.primary}
                                    className="d-block d-sm-none d-lg-block pt-5 p-sm-5 pb-5 px-5"
                                >
                                    <Col>
                                        {(() => {
                                            if (
                                                item.logo.asset.extension ===
                                                "svg"
                                            ) {
                                                return (
                                                    <SVG
                                                        src={
                                                            item.logo.asset.url
                                                        }
                                                        style={{
                                                            maxHeight: "500px",
                                                            maxWidth: "100%",
                                                        }}
                                                        className="mx-auto d-block"
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <img
                                                        src={
                                                            item.logo.asset.url
                                                        }
                                                        alt=""
                                                        style={{
                                                            maxHeight: "500px",
                                                            maxWidth: "100%",
                                                        }}
                                                        className="mx-auto d-block"
                                                    />
                                                );
                                            }
                                        })()}
                                    </Col>
                                </Row>
                            </section>
                        ) : (
                            <header>
                                <h1
                                    className="h1 mt-5 py-4 text-center"
                                    style={Object.assign(
                                        {},
                                        theme.color.primary
                                    )}
                                >
                                    {item.title}
                                </h1>
                            </header>
                        )}

                        {/* Information */}
                        <section>
                            <Row className="px-5">
                                <Col>
                                    {item.title && (
                                        <Row className="d-none d-sm-block pt-4">
                                            <Col>
                                                <header>
                                                    <h1
                                                        className="h1"
                                                        style={
                                                            theme.color.primary
                                                        }
                                                    >
                                                        {item.title}
                                                    </h1>
                                                </header>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col
                                            style={theme.color.primary}
                                            className="p-0 "
                                            xl={12}
                                        >
                                            {item.logo && (
                                                <div className="d-none d-sm-block d-lg-none ">
                                                    {(() => {
                                                        if (
                                                            item.logo.asset
                                                                .extension ===
                                                            "svg"
                                                        ) {
                                                            return (
                                                                <SVG
                                                                    src={
                                                                        item
                                                                            .logo
                                                                            .asset
                                                                            .url
                                                                    }
                                                                    className="w-100 mx-auto p-5 svg-inherit"
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <img
                                                                    src={
                                                                        item
                                                                            .logo
                                                                            .asset
                                                                            .url
                                                                    }
                                                                    alt=""
                                                                    className="w-100 mx-auto p-5 d-lg-none"
                                                                />
                                                            );
                                                        }
                                                    })()}
                                                </div>
                                            )}

                                            {item.liveurl && (
                                                <div className="text-center text-lg-left pt-4">
                                                    <Button
                                                        style={
                                                            theme.btn.fill
                                                                .primary
                                                        }
                                                        href={item.liveurl}
                                                        className="mx-auto"
                                                    >
                                                        <span>
                                                            View live demo
                                                        </span>
                                                        <I
                                                            x="-0.1rem"
                                                            className="ml-2"
                                                        >
                                                            <IoMdArrowRoundForward />
                                                        </I>
                                                    </Button>
                                                </div>
                                            )}
                                        </Col>
                                        <Col
                                            className="mx-auto pb-4 pt-5 pr-xl-5"
                                            style={theme.color.primary}
                                        >
                                            <Row>
                                                {item.clientLogos !== null ||
                                                item.client !== null ? (
                                                    <Col lg={12} md={3}>
                                                        <p className="font-weight-bold font-italic w-100">
                                                            <FaSuitcase
                                                                size="2rem"
                                                                className="mr-2 mb-2"
                                                            />{" "}
                                                            <span>Client:</span>
                                                        </p>
                                                        <p className="mx-auto mb-5 pl-3">
                                                            {item.clientLogos !==
                                                            null
                                                                ? item.clientLogos.map(
                                                                      (
                                                                          logo
                                                                      ) => (
                                                                          <SVG
                                                                              src={
                                                                                  logo
                                                                                      .asset
                                                                                      .url
                                                                              }
                                                                              className="d-inline-block"
                                                                              height="4rem"
                                                                          />
                                                                      )
                                                                  )
                                                                : item.client}
                                                        </p>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}

                                                {item.brief && (
                                                    <Col lg={12} md={9}>
                                                        <p className="font-weight-bold font-italic w-100">
                                                            <FaQuoteRight
                                                                size="2rem"
                                                                className="mr-2 mb-2"
                                                            />{" "}
                                                            <span>
                                                                Task / brief:
                                                            </span>
                                                        </p>
                                                        <p className="mx-auto paragraph text-left">
                                                            <i>{item.brief}</i>
                                                        </p>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={7}>
                                    <Row>
                                        {item.date && (
                                            <div className=" mt-4  pt-3 px-3">
                                                <p
                                                    className="font-weight-bold mb-0"
                                                    style={theme.color.primary}
                                                >
                                                    Year
                                                </p>
                                                <Badge
                                                    style={theme.color.primary}
                                                    className="font-weight-normal"
                                                >
                                                    {item.date.split("-")[0]}
                                                </Badge>
                                            </div>
                                        )}
                                        {item.type && (
                                            <div className=" mt-4  pt-3 px-3">
                                                <p
                                                    className="font-weight-bold mb-0"
                                                    style={theme.color.primary}
                                                >
                                                    Type
                                                </p>
                                                <Badge
                                                    style={theme.color.primary}
                                                    className="font-weight-normal"
                                                >
                                                    {item.type}
                                                </Badge>
                                            </div>
                                        )}
                                        {item.timeSpan && (
                                            <div className="mt-4  pt-3 px-3">
                                                <p
                                                    className="font-weight-bold mb-0"
                                                    style={theme.color.primary}
                                                >
                                                    Timespan
                                                </p>
                                                <Badge
                                                    style={theme.color.primary}
                                                    className="font-weight-normal"
                                                >
                                                    {item.timeSpan || ""}
                                                </Badge>
                                            </div>
                                        )}
                                        {item.team && (
                                            <div className=" mt-4  pt-3 px-3">
                                                <p
                                                    className="font-weight-bold mb-0"
                                                    style={theme.color.primary}
                                                >
                                                    Team
                                                </p>
                                                <Badge
                                                    style={theme.color.primary}
                                                    className="font-weight-normal"
                                                >
                                                    {item.teamSize || ""}
                                                </Badge>
                                            </div>
                                        )}
                                        {item.tech && (
                                            <div
                                                className="mt-4 pt-3 px-3"
                                                style={Object.assign(
                                                    {},
                                                    theme.color.primary
                                                )}
                                            >
                                                {item.tech
                                                    ? item.tech.map((tag) => (
                                                          <CheckTech
                                                              tag={tag}
                                                              theme={theme}
                                                          />
                                                      ))
                                                    : ""}
                                            </div>
                                        )}
                                        {item.contentCreated && (
                                            <div
                                                className="mt-4 pt-3 px-3"
                                                style={Object.assign(
                                                    {},
                                                    theme.color.primary
                                                )}
                                            >
                                                <p
                                                    className="font-weight-bold mb-0"
                                                    style={theme.color.primary}
                                                >
                                                    <GiPencilBrush size="2rem" />{" "}
                                                    Content created
                                                </p>
                                                {item.contentCreated.map(
                                                    (text) => (
                                                        <Badge
                                                            style={
                                                                theme.color
                                                                    .primary
                                                            }
                                                            className="font-weight-normal"
                                                        >
                                                            {text}
                                                        </Badge>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </Row>

                                    <Row className="mt-4  pt-3 pb-5 pb-sm-0">
                                        <Col style={theme.color.primary}>
                                            {separator()}
                                            <p className="font-weight-bold pt-3">
                                                <TiDocument size="2rem" />{" "}
                                                Description
                                            </p>
                                            <p className="paragraph">
                                                {item.description || ""}
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row
                                        className="d-none d-md-flex mt-5 pb-5 px-0"
                                        style={theme.color.primary}
                                    >
                                        <Col className="mb-4">
                                            {separator()}
                                            <p className="font-weight-bold pt-3">
                                                <GiArcheryTarget size="2rem" />{" "}
                                                Goals
                                            </p>
                                            <p>{item.goals || ""}</p>
                                        </Col>
                                        <Col>
                                            {separator()}
                                            <p className="font-weight-bold pt-3">
                                                <GiTrophyCup size="2rem" />{" "}
                                                Learned
                                            </p>
                                            <p>{item.learned || ""}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row
                                className="d-md-none pb-5 px-5"
                                style={theme.color.primary}
                            >
                                <Col xs={12} sm={6} className="pb-5 pb-sm-0">
                                    {separator()}
                                    <p className="font-weight-bold pt-3">
                                        <GiArcheryTarget size="2rem" /> Goals
                                    </p>
                                    <p>{item.goals || ""}</p>
                                </Col>
                                <Col xs={12} sm={6}>
                                    {separator()}
                                    <p className="font-weight-bold pt-3">
                                        <GiTrophyCup size="2rem" /> Learned
                                    </p>
                                    <p>{item.learned || ""}</p>
                                </Col>
                            </Row>
                        </section>

                        {/* Videos */}
                        {item.videos && (
                            <section>
                                <Row
                                    style={theme.color.primary}
                                    className="px-5 pt-5"
                                >
                                    <Col>
                                        <div className="d-flex pb-4">
                                            <p className="h1">
                                                {" "}
                                                <FiVideo size="2rem" />
                                                <br />
                                                Videos
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Container>
                                    <Row className="px-3 px-sm-5">
                                        {item.videos.map((video) => (
                                            <>
                                                {/* <h1>
                                                    {video.asset.playbackId}
                                                </h1> */}
                                                <SanityMuxPlayer
                                                    assetDocument={{
                                                        _id:
                                                            video.asset.assetId,
                                                        _type: "mux.videoAsset",
                                                        playbackId:
                                                            video.asset
                                                                .playbackId,
                                                        status: "ready",
                                                    }}
                                                    autoload={true}
                                                    autoplay={
                                                        mobile ? false : true
                                                    }
                                                    // height={number | percent}
                                                    // width={number | percent}
                                                    loop={true}
                                                    muted={true}
                                                    showControls={true}
                                                />
                                            </>
                                        ))}
                                    </Row>
                                </Container>
                            </section>
                        )}

                        {/* Images */}
                        {item.carousel && item.carousel.length > 0 ? (
                            <section>
                                <Row
                                    style={theme.color.primary}
                                    className="px-5 pt-5"
                                >
                                    <Col>
                                        <div className="d-flex pb-4">
                                            <p className="h1">
                                                {" "}
                                                <FiImage size="2rem" />
                                                <br />
                                                Images
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Container>
                                    <Row className="px-3 px-sm-5">
                                        {item.carousel.map((image) => (
                                            <img
                                                src={
                                                    image.asset.url +
                                                    "?fm=jpg&q=70"
                                                }
                                                alt=""
                                                style={{
                                                    objectFit: "cover",
                                                    maxWidth: "100%",
                                                    alignSelf: "flex-start",
                                                }}
                                                className="mx-auto w-100"
                                            />
                                        ))}
                                    </Row>
                                </Container>
                            </section>
                        ) : (
                            " "
                        )}

                        {/* Behind the scenes */}
                        {item.images && item.images.length > 0 ? (
                            <section>
                                <>
                                    <Row
                                        style={theme.color.primary}
                                        className="px-5 pt-5"
                                    >
                                        <Col>
                                            <div className="d-flex pb-4">
                                                <p className="h1">
                                                    {" "}
                                                    <GiGears size="2rem" />
                                                    <br />
                                                    Behind the scenes:
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="px-5">
                                        <Masonry
                                            breakpointCols={
                                                breakpointColumnsObj
                                            }
                                            columnClassName="p-0"
                                        >
                                            {item.images.map((img) => (
                                                <>
                                                    {img.asset.extension ===
                                                    "gif" ? (
                                                        <img
                                                            src={
                                                                img.asset.url +
                                                                "?w=1400&q=65"
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    "contain",
                                                                width: "100%",
                                                                height: "auto",
                                                            }}
                                                            className="d-block"
                                                            alt=""
                                                            height="auto"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={
                                                                img.asset.url +
                                                                "?fm=jpg&w=1400&q=65"
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    "contain",
                                                                width: "100%",
                                                                height: "auto",
                                                            }}
                                                            className="d-block"
                                                            alt=""
                                                            height="auto"
                                                        />
                                                    )}
                                                </>
                                            ))}
                                        </Masonry>
                                    </Row>
                                </>
                            </section>
                        ) : (
                            ""
                        )}

                        {/* Bottom navigation */}
                        <footer>
                            <Row
                                className="px-5 py-3 "
                                style={theme.bg.secondary}
                            >
                                <Col
                                    className="m-0 p-0"
                                    style={Object.assign(
                                        {},
                                        theme.color.primary
                                    )}
                                >
                                    <h1 className="h5">{item.title || ""}</h1>

                                    {separator()}
                                </Col>
                                <Col className="p-0 text-right">
                                    <AnchorLink href="#top">
                                        <Button style={theme.btn.fill.primary}>
                                            <span>Go to top</span>
                                            <I x="-0.1rem" className="ml-2">
                                                <IoMdArrowRoundUp />
                                            </I>
                                        </Button>
                                    </AnchorLink>
                                    {item.liveurl ? (
                                        <Button
                                            style={theme.btn.fill.primary}
                                            href={item.liveurl}
                                        >
                                            <span>Demo</span>
                                            <I x="-0.1rem" className="ml-2">
                                                <IoMdArrowRoundForward />
                                            </I>
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                            </Row>
                        </footer>
                    </article>
                </section>
            </main>
            {/* <Footer
            // color={item.textColor.hex}
            /> */}
        </>
    );
};

export default PortfolioItemDetails;
