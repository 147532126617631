import React from "react";
import Emoji from "./Emoji";

const Heading = ({ children, h1, emojiLeft, emojiRight, className }) => {
    return (
        <>
            <header className={`text-center w-100 ${className}`}>
                <>
                    <span className={`text-left w-100 ${className}`}>
                        <h1 className="heading">
                            <Emoji symbol={emojiLeft} label="" top="" size="" />
                            {h1}
                            <Emoji
                                symbol={emojiRight}
                                label=""
                                top=""
                                size=""
                            />
                        </h1>
                        {children}
                    </span>
                </>
            </header>
        </>
    );
};

export default Heading;
