import React from "react";
import { NavLink } from "react-router-dom";

// External components

// Bootstrap
import { Row, Col, Button, Badge } from "react-bootstrap";
import SVG from "react-inlinesvg";

// Styling

// Internal components
// import PortfolioCarousel from "components/portfolio/PortfolioCarousel";
import CheckTech from "components/CheckTech";
import getTheme from "functions/theme";

// Icons
// React icons: https://react-icons.netlify.com/#/search
import { IoMdMore, IoMdArrowRoundForward } from "react-icons/io";
import I from "components/layout/icons/IconContainer";
import { FaQuoteRight } from "react-icons/fa";

// Transitions

const PortfolioItem = ({ item, list }) => {
    let theme = getTheme(item);

    const darken = (item) => {
        const r = item.bgColor.rgb.r;
        const g = item.bgColor.rgb.g;
        const b = item.bgColor.rgb.b;
        if (r + g + b > 300) {
            return "darken";
        }
    };
    const tooBright = (item) => {
        // const r = item.bgColor.rgb.r;
        // const g = item.bgColor.rgb.g;
        // const b = item.bgColor.rgb.b;
        // if (r + g + b > 300) {
        //     return true;
        // }
        return false;
    };
    return (
        <>
            <div
                className={`${
                    list ? "portfolioItemContainer" : ""
                } position-relative`}
            >
                <div
                    style={{
                        // background: `url(${
                        //     background + "?fm=jpg&blur=50&w=700&q=50"
                        // }) center center / cover no-repeat`,
                        // background: item.bgColor.hex,
                        position: "absolute",
                        width: "100vw",
                        height: "100%",
                        opacity: !list ? 0 : 100,
                        filter: "contrast(60%) brightness(70%)",
                    }}
                    className="d-none d-sm-block"
                />
                <div
                    className={`overflow-hidden portfolioItem rounded-2 position-relative portfolioItem--hover ${darken(
                        item
                    )}`}
                    style={
                        tooBright(item) ? theme.bg.primary : theme.bg.secondary
                    }
                >
                    {!list ? (
                        <Row
                            style={{
                                position: "absolute",
                                top: "0.25rem",
                                left: "1rem",
                                zIndex: 1,
                            }}
                        >
                            <Col className="pt-1">
                                {/* {item.tech.map((t) => (
                                    <Badge
                                        style={Object.assign(
                                            theme.btn.fill.primary,
                                            { margin: "0 0.1rem" }
                                        )}
                                    >
                                        {t}
                                    </Badge>
                                ))} */}
                                {/* <Badge style={theme.btn.fill.primary}>
                                    {item.date ? item.date.split("-")[0] : ""}
                                </Badge>
                                <Badge
                                    style={theme.btn.fill.secondary}
                                    className="mr-1"
                                >
                                    {item.type ? item.type : ""}
                                </Badge> */}
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                    <Row>
                        <Col xs={12} className="p-0 m-0">
                            {!list ? (
                                <NavLink to={`/portfolio/${item.slug.current}`}>
                                    {(() => {
                                        if (
                                            item.thumb.asset.extension === "svg"
                                        ) {
                                            return (
                                                <div
                                                    className={`w-100 d-flex p-5`}
                                                >
                                                    <SVG
                                                        src={
                                                            item.thumb.asset.url
                                                        }
                                                        className="mx-auto portfolioItem__svg py-1 w-100"
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <img
                                                    src={
                                                        item.thumb.asset.url +
                                                        "?fm=jpg&q=70&sharpen=60&w=800"
                                                    }
                                                    alt=""
                                                    draggable="false"
                                                    className={`w-100 portfolioItem__img`}
                                                />
                                            );
                                        }
                                    })()}
                                    <h1
                                        className="h6 d-block mb-1 mt-2 px-4"
                                        style={{
                                            color: tooBright(item)
                                                ? item.bgColor.hex
                                                : item.textColor.hex,
                                        }}
                                    >
                                        {/* {item.shortdescription} */}
                                        {item.title}
                                    </h1>
                                </NavLink>
                            ) : (
                                ""
                            )}
                            {list ? (
                                <NavLink to={`/portfolio/${item.slug.current}`}>
                                    <div>
                                        <div className="position-absolute pl-4 pt-2">
                                            <Badge
                                                style={theme.btn.fill.primary}
                                            >
                                                {item.date
                                                    ? item.date.split("-")[0]
                                                    : ""}
                                            </Badge>
                                            <Badge
                                                style={theme.btn.fill.secondary}
                                                className="mr-1"
                                            >
                                                {item.type ? item.type : ""}
                                            </Badge>
                                        </div>
                                        {/* {item.tech.map((t) => (
                                                <Badge
                                                    style={Object.assign(
                                                        theme.btn.fill.primary,
                                                        { margin: "0 0.1rem" }
                                                    )}
                                                >
                                                    {t}
                                                </Badge>
                                            ))} */}
                                        <img
                                            src={
                                                item.carousel[0].asset.url +
                                                "?fm=jpg&w=1500&fit=crop&w=65&crop=top"
                                            }
                                            alt=""
                                            className="w-100 portfolioListThumbnail"
                                        />
                                    </div>
                                </NavLink>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col>
                            <Row className={list ? "p-md-4" : ""}>
                                {list ? (
                                    <Col
                                        xs={12}
                                        className="w-100 px-4 pt-4 pb-3"
                                        style={{
                                            color: tooBright(item)
                                                ? item.bgColor.hex
                                                : item.textColor.hex,
                                        }}
                                    >
                                        <div
                                            xs={12}
                                            className="text-left d-sm-none pb-2"
                                            style={{
                                                fill: tooBright(item)
                                                    ? item.bgColor.hex
                                                    : item.textColor.hex,
                                            }}
                                        >
                                            {item.tech
                                                ? item.tech.map((tag) => (
                                                      <CheckTech
                                                          tag={tag}
                                                          theme={theme}
                                                      />
                                                  ))
                                                : ""}
                                        </div>
                                        <h1 className="h2 d-block mb-3">
                                            {item.title}
                                        </h1>
                                        <p className="paragraph font-italic">
                                            <FaQuoteRight
                                                size="2rem"
                                                className="mr-3 mb-2"
                                            />
                                            {item.brief.substring(0, 100) +
                                                "..."}
                                        </p>
                                    </Col>
                                ) : (
                                    ""
                                )}
                                <Col
                                    xs={12}
                                    className={list ? "pb-4" : "px-4 py-3"}
                                >
                                    <Row className="p-0 m-0">
                                        {list ? (
                                            <>
                                                <Col
                                                    className="text-left d-none d-sm-block"
                                                    style={{
                                                        fill: tooBright(item)
                                                            ? item.bgColor.hex
                                                            : item.textColor
                                                                  .hex,
                                                    }}
                                                >
                                                    {item.tech
                                                        ? item.tech.map(
                                                              (tag) => (
                                                                  <CheckTech
                                                                      tag={tag}
                                                                      theme={
                                                                          theme
                                                                      }
                                                                  />
                                                              )
                                                          )
                                                        : ""}
                                                </Col>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <Col className="p-0">
                                            <Row className="p-0 m-0">
                                                <Col className="p-0">
                                                    <NavLink
                                                        to={`/portfolio/${item.slug.current}`}
                                                    >
                                                        <Button
                                                            style={
                                                                tooBright(item)
                                                                    ? theme.btn
                                                                          .outline
                                                                          .secondary
                                                                    : theme.btn
                                                                          .outline
                                                                          .primary
                                                            }
                                                            variant="outline-transparent"
                                                            className="mr-2 py-1 w-100 px-0"
                                                        >
                                                            <span>Details</span>
                                                            <I
                                                                x="-0.1rem"
                                                                className="ml-1"
                                                            >
                                                                <IoMdMore />
                                                            </I>
                                                        </Button>
                                                    </NavLink>
                                                </Col>
                                                {item.liveurl ? (
                                                    <Col className="p-0">
                                                        <Button
                                                            style={
                                                                tooBright(item)
                                                                    ? theme.btn
                                                                          .fill
                                                                          .secondary
                                                                    : theme.btn
                                                                          .fill
                                                                          .primary
                                                            }
                                                            variant="transparent"
                                                            href={item.liveurl}
                                                            className="py-1 w-100 px-0"
                                                        >
                                                            <span>Demo</span>
                                                            <I
                                                                x="-0.1rem"
                                                                className="ml-1"
                                                            >
                                                                <IoMdArrowRoundForward />
                                                            </I>
                                                        </Button>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}
                                                {item.pdf ? (
                                                    <Col className="p-0">
                                                        <Button
                                                            style={
                                                                tooBright(item)
                                                                    ? theme.btn
                                                                          .fill
                                                                          .secondary
                                                                    : theme.btn
                                                                          .fill
                                                                          .primary
                                                            }
                                                            href={
                                                                item.pdf.asset
                                                                    .url
                                                            }
                                                            className="py-1 w-100 px-0"
                                                        >
                                                            <span>pdf</span>
                                                            <I
                                                                x="-0.1rem"
                                                                className="ml-1"
                                                            >
                                                                <IoMdArrowRoundForward />
                                                            </I>
                                                        </Button>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {/* <Container fluid="true" className="mt-5">
                            <div className="text-center w-100">
                                <NavLink to={`/portfolio/${item.slug.current}`}>
                                    <Button
                                        variant="outline-light"
                                        eventKey="1"
                                        className="mr-2 py-1"
                                    >
                                        <span>Details</span>
                                        <I x="-0.1rem" className="ml-1">
                                            <IoMdMore />
                                        </I>
                                    </Button>
                                </NavLink>
                                {item.liveurl ? (
                                    <Button
                                        href={item.liveurl}
                                        variant="light"
                                        className="py-1"
                                    >
                                        <span>Demo</span>
                                        <I x="-0.1rem" className="ml-1">
                                            <IoMdArrowRoundForward />
                                        </I>
                                    </Button>
                                ) : (
                                    ""
                                )}
                                {item.pdf ? (
                                    <Button
                                        style={theme.btn.fill.primary}
                                        href={item.pdf.asset.url}
                                        className="py-1"
                                    >
                                        <span>pdf</span>
                                        <I x="-0.1rem" className="ml-1">
                                            <IoMdArrowRoundForward />
                                        </I>
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Container> */}
            </div>
        </>
    );
};

export default PortfolioItem;
