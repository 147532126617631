import React from "react";

const IconContainer = ({ icon, x, y, children, className }) => {
    return (
        <span
            style={{ position: "relative", top: y, left: x }}
            className={className}
        >
            {children}
        </span>
    );
};

export default IconContainer;
