import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Internal components
import PortfolioItemDetails from "components/portfolio/PortfolioItemDetails";
import { Sanity } from "../services/sanity.service";

const PortfolioSpecific = (props) => {
    const { projects } = useContext(Sanity);
    let { slug } = useParams();
    const project = projects.filter((project) => project.slug.current === slug);
    // If fetching specific project, without context:
    // const [project, setProject] = useState([]);
    // let { slug } = useParams();
    // useEffect(() => {
    //     fetchProject(slug).then(setProject);
    // }, [slug]);

    return (
        <>
            {/* <Bg /> */}

            {project.map((item) => (
                <Helmet>
                    <title>LDS > Portfolio > {item.title}</title>
                </Helmet>
            ))}
            {project.map((item, index) => (
                <div className="mb-5">
                    <PortfolioItemDetails
                        item={item}
                        id="item"
                        expanded={true}
                    />
                </div>
            ))}
        </>
    );
};

export default PortfolioSpecific;
