import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Button from "react-bootstrap/Button";

const Back = ({ history, className, style, variant, sticky, location }) => {
    window.addEventListener("scroll", () => {
        const isTop = window.scrollY > 100;
        if (isTop) {
            if (!scrolled) {
                setScrolled(true);
            }
        } else {
            if (scrolled) {
                setScrolled(false);
            }
        }
    });
    if (sticky) {
        variant = "light";
    }
    const [scrolled, setScrolled] = useState(false);
    return (
        <div
            className={`backBtn
            ${className}
            ${sticky ? "backBtn__sticky" : ""}
            ${scrolled ? "backBtn__sticky--stuck" : ""}
            ${location.pathname === "/" ? "backBtn--home" : ""}
            `}
        >
            <Button
                className=" w-100 text-left px-4 rounded-pill subtle-shadow"
                variant={variant || "outline-primary"}
                onClick={history.goBack}
            >
                <IoMdArrowRoundBack
                    size="1.5rem"
                    style={{
                        transform: "translateY(-0.15rem)",
                    }}
                />
                <span className="ml-2">Tilbake</span>
            </Button>
        </div>
    );
};

export default withRouter(Back);
