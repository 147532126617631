import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { SanityProvider } from "./services/sanity.service";
import "sass/main.scss";

// import Favicon from "react-favicon";

ReactDOM.render(
    <SanityProvider>
        <App />
    </SanityProvider>,
    document.getElementById("root")
);
