import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

// Bootstrap
import Button from "react-bootstrap/Button";

// Icons
// React icons: https://react-icons.netlify.com/#/search
import { IoMdArrowRoundUp } from "react-icons/io";
import I from "components/layout/icons/IconContainer";

const ScrollTop = ({ location }) => {
    window.addEventListener("scroll", () => {
        const isTop = window.scrollY > 100;
        if (isTop) {
            if (!scrolled) {
                setScrolled(true);
            }
        } else {
            if (scrolled) {
                setScrolled(false);
            }
        }
    });
    const [scrolled, setScrolled] = useState(false);

    return (
        <AnchorLink
            href="#top"
            className={` scrollTop
        ${scrolled ? "scrollTop--stuck" : ""}
        
        ${location === "/" ? "scrollTop--home" : ""}
        `}
        >
            <Button variant="light" className="subtle-shadow rounded-pill">
                <span>Topp</span>
                <I x="-0.1rem" className="ml-2">
                    <IoMdArrowRoundUp />
                </I>
            </Button>
        </AnchorLink>
    );
};

export default ScrollTop;
