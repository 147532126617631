import React from "react";
import { FaBehance, FaGithub, FaLinkedin } from "react-icons/fa";
import Button from "react-bootstrap/button";

import {
    SOCIAL_GITHUB,
    SOCIAL_BEHANCE,
    SOCIAL_LINKEDIN,
} from "constants/links";

const Social = ({ size, color, className, variant }) => {
    if (size) {
        size += "rem";
    } else {
        size = "2rem";
    }

    return (
        <div className={className + " social"}>
            <Button
                href={SOCIAL_GITHUB}
                variant={variant || "transparent"}
                target="_blank"
            >
                <FaGithub size={size} color={color || "inherit"} />
            </Button>
            <Button
                href={SOCIAL_BEHANCE}
                variant={variant || "transparent"}
                target="_blank"
            >
                <FaBehance size={size} color={color || "inherit"} />
            </Button>
            <Button
                href={SOCIAL_LINKEDIN}
                variant={variant || "transparent"}
                target="_blank"
            >
                <FaLinkedin size={size} color={color || "inherit"} />
            </Button>
        </div>
    );
};

export const SocialSingle = ({ size, color, className, variant, type }) => {
    if (size) {
        size += "rem";
    } else {
        size = "2rem";
    }
    switch (type.toLowerCase()) {
        case "github":
            return (
                <Button
                    href={SOCIAL_GITHUB}
                    variant={variant || "transparent"}
                    className={className}
                >
                    <FaGithub size={size} color={color || "inherit"} />
                </Button>
            );
        case "linkedin":
            return (
                <Button
                    href={SOCIAL_LINKEDIN}
                    variant={variant || "transparent"}
                    className={className}
                >
                    <FaLinkedin size={size} color={color || "inherit"} />
                </Button>
            );
        case "behance":
            return (
                <Button
                    href={SOCIAL_BEHANCE}
                    variant={variant || "transparent"}
                    className={className}
                >
                    <FaBehance size={size} color={color || "inherit"} />
                </Button>
            );
        default:
            // do nothing;
            break;
    }
};

export default Social;
