import React from "react";

const Author = ({ className }) => {
    return (
        <div className={`${className} h6 text-left author`}>
            <p className="mb-1">
                <i>Lasse Dollis Spilling</i>
            </p>
            <pre>Frontend-utvikler &amp; grafisk designer</pre>
        </div>
    );
};

export default Author;
