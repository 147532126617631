import { borderWidth } from "sass/partials/_variables.scss";
const customBorderWidth = borderWidth;
const getTheme = (item) => {
    return {
        color: {
            primary: {
                color: item.textColor.hex || "inherit",
            },
            secondary: {
                color: item.bgColor.hex || "inherit",
            },
        },
        bg: {
            primary: {
                backgroundColor: item.textColor.hex || "inherit",
            },
            secondary: {
                backgroundColor: item.bgColor.hex || "white",
            },
        },
        btn: {
            fill: {
                primary: {
                    backgroundColor: item.textColor.hex || "inherit",
                    boxShadow: "none",
                    backgroundImage: "none",
                    backgroundClip: "border-box",
                    color: item.bgColor.hex || "inherit",
                    borderColor: item.textColor.hex || "inherit",
                },
                secondary: {
                    backgroundColor: item.bgColor.hex || "inherit",
                    color: item.textColor.hex || "inherit",
                    borderColor: item.bgColor.hex || "inherit",
                },
            },
            outline: {
                primary: {
                    backgroundColor: "transparent",
                    borderColor: item.textColor.hex || "inherit",
                    color: item.textColor.hex || "inherit",
                    boxShadow: `0px 2px 0px ${item.textColor.hex} inset`,
                },
                secondary: {
                    backgroundColor: "transparent",
                    borderColor: item.bgColor.hex || "inherit",
                    color: item.bgColor.hex || "inherit",
                },
            },
        },
        border: {
            primary: {
                borderColor: item.textColor.hex || "inherit",
            },
            secondary: {
                borderColor: item.bgColor.hex || "inherit",
            },
            width: {
                borderWidth: customBorderWidth,
            },
            bottom: {
                borderBottom: customBorderWidth + " solid",
            },
            top: {
                borderTop: customBorderWidth + " solid",
            },
            left: {
                borderLeft: customBorderWidth + " solid",
            },
            right: {
                borderRight: customBorderWidth + " solid",
            },
        },
    };
};
export default getTheme;
