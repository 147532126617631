import React from "react";
import { FaSass, FaReact, FaBootstrap } from "react-icons/fa";
import SVG from "react-inlinesvg";
import {
    HTML5,
    Framer,
    CSS3,
    NextJs,
    Javascript,
    Jquery,
} from "components/layout/icons/Icons"; // custom colored svg icons

// Bootstrap
import Badge from "react-bootstrap/Badge";

const CheckTech = ({ tag, theme }) => {
    switch (tag.toLowerCase()) {
        case "html":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a
                        href="https://www.w3schools.com/html/html5_intro.asp"
                        title={tag}
                    >
                        <HTML5 size="2rem" />
                    </a>
                </Badge>
            );
        case "html5":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a
                        href="https://www.w3schools.com/html/html5_intro.asp"
                        title={tag}
                    >
                        <HTML5 size="2rem" />
                    </a>
                </Badge>
            );
        case "css":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a
                        href="https://en.wikipedia.org/wiki/Cascading_Style_Sheets"
                        title={tag}
                    >
                        <CSS3 size="2rem" />
                    </a>
                </Badge>
            );
        case "css3":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a
                        href="https://en.wikipedia.org/wiki/Cascading_Style_Sheets"
                        title={tag}
                    >
                        <CSS3 size="2rem" />
                    </a>
                </Badge>
            );
        case "javascript":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://www.javascript.com/" title={tag}>
                        <Javascript size="2rem" />
                    </a>
                </Badge>
            );
        case "bootstrap":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://getbootstrap.com/" title={tag}>
                        <FaBootstrap size="2rem" color="#7754A3" />
                    </a>
                </Badge>
            );
        case "jquery":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://jquery.com/" title={tag}>
                        <Jquery size="1rem" />
                    </a>
                </Badge>
            );
        case "adobe xd":
            return (
                <Badge
                    variant="transparent"
                    className="hoverScale"
                    style={{ fontSize: "1rem" }}
                >
                    <a
                        href="https://www.adobe.com/products/xd.html"
                        title={tag}
                    >
                        <SVG
                            src="https://cdn.sanity.io/images/23x9eh58/production/36f9a1a6e310c80c89128c19269178b58693279a-240x234.svg"
                            height="2rem"
                            width="2rem"
                        />
                    </a>
                </Badge>
            );
        case "photoshop":
            return (
                <Badge
                    variant="transparent"
                    className="hoverScale"
                    style={{ fontSize: "1rem" }}
                >
                    <a
                        href="https://www.adobe.com/no/products/photoshop.html"
                        title={tag}
                    >
                        <SVG
                            src="https://cdn.sanity.io/images/23x9eh58/production/661add6ef520de95b3fe811090826c3cb99115ab-240x234.svg?h=500&fit=max"
                            height="2rem"
                            width="2rem"
                        />
                    </a>
                </Badge>
            );
        case "illustrator":
            return (
                <Badge
                    variant="transparent"
                    className="hoverScale"
                    style={{ fontSize: "1rem" }}
                >
                    <a
                        href="https://www.adobe.com/products/illustrator.html"
                        title={tag}
                    >
                        <SVG
                            src="https://cdn.sanity.io/images/23x9eh58/production/7edee4cf2622885bd0078fe9a38be83d85c87665-240x234.svg?h=500&fit=max"
                            height="2rem"
                            width="2rem"
                        />
                    </a>
                </Badge>
            );
        case "next.js":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://nextjs.org/" title={tag}>
                        <NextJs size="2rem" />
                    </a>
                </Badge>
            );
        case "sass":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://sass-lang.com/" title={tag}>
                        <FaSass size="2rem" color="CF679A" />
                    </a>
                </Badge>
            );
        case "react":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://reactjs.org/" title={tag}>
                        <FaReact size="2rem" color="61DCFB" />
                    </a>
                </Badge>
            );
        case "framer":
            return (
                <Badge variant="transparent" className="hoverScale">
                    <a href="https://www.framer.com/" title={tag}>
                        <Framer size="2rem" color="CF679A" />
                    </a>
                </Badge>
            );
        default:
            return <Badge style={theme.btn.fill.primary}>{tag}</Badge>;
    }
};

export default CheckTech;
