import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

// Internal components
import Heading from "components/layout/Heading";
import HomeCarousel from "components/carousel/HomeCarousel";
import I from "components/layout/icons/IconContainer";
import Social from "components/social/Social";
import Author from "components/info/Author";

// React icons: https://react-icons.netlify.com/#/search
import { IoMdArrowRoundForward } from "react-icons/io";

// Bootstrap
import { Row, Col, Container, Button } from "react-bootstrap";

// Sanity
import { Sanity } from "services/sanity.service";

const Home = (props) => {
    const { carouselPlay, setCarouselPlay } = useState(true);
    const { projects } = useContext(Sanity);
    const projectsToShow = projects;
    const title = (
        <>
            <span className="gradient">
                Jeg forenkler, <br />
                forsterker og fornyer.
            </span>
        </>
    );

    return (
        <>
            {/* <Bg /> */}
            <Helmet>
                <title>LDS > Lasse D. Spilling</title>
            </Helmet>
            <main className="mb-5 pb-5 px-0 mb-lg-0 pb-lg-0">
                <Container fluid="true">
                    <Row className="px-0 pt-md-5  pt-lg-0 my-lg-4 d-md-flex mt-md-0 mb-md-5 pt-2 pt-sm-0">
                        <Col
                            xs={12}
                            xl={4}
                            lg={4}
                            md={5}
                            className="text-center pt-sm-4 pt-lg-0 px-0"
                            style={{
                                transform: "translateX(10vw)",
                            }}
                        >
                            <Heading
                                className="px-0 my-3 my-sm-5 my-md-0 ml-lg-5"
                                h1={title}
                            >
                                <Author className="d-md-none" />
                            </Heading>
                            <div className="w-100 text-left d-none d-md-block">
                                <NavLink to="/portfolio">
                                    <Button
                                        variant="primary"
                                        className="rounded-pill"
                                    >
                                        <span className="mr-2">
                                            Åpne portefølje
                                        </span>
                                        <I y="-0.1rem">
                                            <IoMdArrowRoundForward size="1.1rem" />
                                        </I>
                                    </Button>
                                </NavLink>
                                <Author className="pt-5 pl-lg-5" />
                            </div>
                        </Col>
                        <Col
                            xl={8}
                            lg={8}
                            sm={12}
                            md={7}
                            className="order-2 order-md-1 pt-3 pt-sm-0"
                        >
                            <div>
                                <HomeCarousel
                                    onMouseEnter={() => setCarouselPlay(false)}
                                    onMouseLeave={() => setCarouselPlay(true)}
                                    pauseOnHover={carouselPlay}
                                    sm={2}
                                    md={1}
                                    lg={3}
                                    xl={4}
                                    className="ml-lg-5"
                                    itemClasses=""
                                    imgClasses=""
                                    projects={projectsToShow}
                                />
                            </div>
                        </Col>
                        <Col className="order-2 order-md-2 px-1 pt-3 pt-sm-0">
                            <Row className="m-0 p-0 mb-sm-4 text-sm-center text-lg-center px-4 px-lg-0">
                                <Col
                                    lg={4}
                                    className="d-none d-lg-block pt-lg-5"
                                >
                                    <Social />
                                </Col>
                                <Col
                                    xs={6}
                                    lg={3}
                                    className="p-0 offset-lg-1 pt-md-4"
                                >
                                    <NavLink to="/about">
                                        <Button
                                            variant="grey"
                                            align="center"
                                            className="rounded-pill px-3"
                                        >
                                            <span className="h5 font-weight-bold text-center">
                                                Les mer
                                            </span>
                                        </Button>
                                    </NavLink>
                                </Col>
                                <Col xs={6} lg={3} className="p-0 m-0 pt-md-4">
                                    <NavLink
                                        to="/portfolio"
                                        className="p-0 m-0"
                                    >
                                        <Button
                                            variant="transparent-primary"
                                            align="center"
                                            className="rounded-pill px-0 px-sm-3"
                                        >
                                            <span className="h5 text-center font-weight-bold">
                                                <I className="mr-2">
                                                    <IoMdArrowRoundForward />
                                                </I>
                                                Se arbeid
                                            </span>
                                        </Button>
                                    </NavLink>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    );
};

export default Home;
