import React from "react";

// internal components
import Darkmode from "components/layout/Darkmode";
import NavBottom from "components/nav/NavBottom";
import { FaQuoteRight } from "react-icons/fa";
// import { primaryColor, secondaryColor } from "sass/partials/_variables.scss";

const Footer = ({ location, color }) => {
    // const [loc, setLoc] = React.useState({});

    // React.useEffect(() => {
    //     const fetchData = async () => {
    //         const result = await axios("https://ipapi.co/json");

    //         setLoc(result.data);
    //     };

    //     fetchData();
    // }, []);
    // const getLatitude = navigator.geolocation.getCurrentPosition(
    //     (position) => position.coords.latitude
    // );
    // const getLongitude = navigator.geolocation.getCurrentPosition(
    //     (position) => position.coords.longitude
    // );
    // Quotes
    const quote = require("inspirational-quotes");
    const Quote = quote.getQuote();
    return (
        <>
            <div
                className="text-center w-100 d-flex justify-content-center flex-column px-5"
                style={{ padding: "20rem 0 10rem 0" }}
            >
                <figure className="quote mx-auto" style={{ color: color }}>
                    <blockquote>
                        <FaQuoteRight size="2rem" className="mb-3" /> <br />
                        <q>{Quote.text}</q>
                        <figcaption>
                            <small>
                                <strong className="text-primary">
                                    - {Quote.author}
                                </strong>
                            </small>
                        </figcaption>
                    </blockquote>
                </figure>
            </div>
            <NavBottom home={location === "/" ? true : false} color={color} />
            <Darkmode location={location} color={color} />
        </>
    );
};

export default Footer;
