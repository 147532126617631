import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// External components
import Masonry from "react-masonry-css";

// Bootstrap
import { Row, Col, Container, Button, Form } from "react-bootstrap";

// Internal components
import Heading from "components/layout/Heading";
import PortfolioItem from "components/portfolio/PortfolioItem";
import { Sanity } from "../services/sanity.service";
import Search from "components/Search";

// Icons
import { FaDesktop, FaMobileAlt } from "react-icons/fa";
import { IoIosList, IoMdGrid, IoMdListBox } from "react-icons/io";
import { MdPhotoSizeSelectLarge } from "react-icons/md";

const Portfolio = (props) => {
    const { projects, categories } = useContext(Sanity);
    const [list, toggleList] = useState(false);

    const sortedProjects = projects
        .slice()
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const [shownProjects, setShownProjects] = useState([]);

    const searchProjects = function (e) {
        const searchValue = e.target.value.toLowerCase();

        const searchedProjects = sortedProjects.filter((proj) => {
            const lowerCaseValue = proj.title.toLowerCase();
            if (lowerCaseValue.includes(searchValue)) {
                return true;
            }
            return false;
        });
        setShownProjects(searchedProjects);
    };
    const toggleView = (view) => {
        if (view === "grid") {
            toggleList(false);
        } else if (view === "list") {
            toggleList(true);
        }
    };

    useEffect(() => {
        if (shownProjects.length < 1 || shownProjects === undefined) {
            setShownProjects(sortedProjects);
        }
    }, [sortedProjects, shownProjects]);

    const filterProjects = (type, e) => {
        if (e.target.value) {
            // If a value is selected
            if (type === "category") {
                // if sorting by category
                let filteredProjects = sortedProjects.filter((project) => {
                    const category =
                        project.categories !== null
                            ? project.categories
                                  .map((category) => category.title)
                                  .join()
                            : "";
                    if (category.includes(e.target.value)) {
                        return true;
                    }
                    return false;
                });

                setShownProjects(filteredProjects);
            }
        } else {
            setShownProjects(sortedProjects);
        }
    };

    const breakpointColumnsObj = {
        default: list ? 1 : 6,
        2000: list ? 1 : 4,
        1500: list ? 1 : 4,
        1200: list ? 1 : 3,
        992: list ? 1 : 2,
        768: list ? 1 : 2,
        670: list ? 1 : 1,
    };
    return (
        <>
            <Helmet>
                <title>LDS > Portfolio</title>
            </Helmet>
            <main>
                <Container fluid>
                    <Row className="pt-5">
                        <Heading
                            className="text-center mb-5"
                            h1={
                                <>
                                    <FaDesktop />
                                    <FaMobileAlt className="mr-4" />
                                    Portfolio
                                </>
                            }
                        />
                    </Row>
                    <Row className="text-center text-xl-left pb-4 px-sm-5">
                        <Col
                            xs={12}
                            lg={4}
                            className="order-3 order-sm-3 order-lg-1"
                        >
                            <Button
                                onClick={() => toggleView("grid")}
                                variant="transparent"
                                className={list ? "opacity-40" : ""}
                            >
                                <span className="d-sm-none">
                                    <IoMdListBox size="2rem" className="mr-2" />
                                    Card view
                                </span>

                                <span className="d-none d-sm-inline-block">
                                    <IoMdGrid size="2rem" className="mr-2" />
                                    Grid view
                                </span>
                            </Button>
                            <Button
                                onClick={() => toggleView("list")}
                                variant="transparent"
                                className={!list ? "opacity-40" : ""}
                            >
                                <span className="d-sm-none">
                                    <MdPhotoSizeSelectLarge
                                        size="2rem"
                                        className="mr-2"
                                    />
                                    Expanded view
                                </span>
                                <span className="d-none d-sm-inline-block">
                                    <IoIosList size="2rem" className="mr-2" />
                                    List view
                                </span>
                            </Button>
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="order-1 order-sm-2 pb-4"
                        >
                            <Search handleSearch={searchProjects} />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="order-sm-2 order-2 pb-4"
                        >
                            <Form.Control
                                as="select"
                                className="btn-outline-dark rounded "
                                onChange={(e) => filterProjects("category", e)}
                            >
                                <option value="" selected>
                                    Sort by category
                                </option>
                                {categories.map((category) => (
                                    <option
                                        value={
                                            category !== null
                                                ? category.title
                                                : ""
                                        }
                                    >
                                        {category !== null
                                            ? category.title
                                            : ""}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                </Container>
                <div
                    className={`portfolioGrid ${
                        !list ? "portfolioGrid--grid" : ""
                    }`}
                >
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid portfolioItems"
                        columnClassName="my-masonry-grid_column"
                    >
                        {shownProjects.map((item) => (
                            <div className={`${!list ? "mb-4" : ""}`}>
                                <PortfolioItem item={item} list={list} />
                                {/* <h1 style={{ color: "red" }}>
                                    {item.categories.map(
                                        (category) => category.title
                                    )}
                                </h1> */}
                            </div>
                        ))}
                    </Masonry>
                </div>
            </main>
        </>
    );
};

export default Portfolio;
