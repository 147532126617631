import React, { useEffect, useState } from "react";

// Icons
import { WiDaySunny, WiMoonAltWaxingCrescent5 } from "react-icons/wi";
import { FiArrowDownLeft } from "react-icons/fi";

// External components
import KeyboardEventHandler from "react-keyboard-event-handler";
import Switch from "react-switch";
import Clock from "react-live-clock";

// Internal components
import UseStickyState from "components/UseStickyState";

const Darkmode = ({ color }) => {
    const [darkmodeStatus, setDarkmodeStatus] = UseStickyState(false);
    const toggleDarkmode = () => {
        setDarkmodeStatus(!darkmodeStatus);
    };

    // OS preference based darkmode
    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    const [prefers, setPrefers] = useState(mql.matches);
    const testPrefers = (e) => {
        if (e.matches) {
            // Prefers color dark === true
            setPrefers(true);
        } else {
            setPrefers(false);
        }
    };
    // Listen for change
    mql.addListener(testPrefers);

    // If OS changes, change darkmode accordingly
    // Time based darkmode
    const d = new Date();
    const hours = d.getHours();
    useEffect(() => {
        if (prefers || hours > 19 || hours < 6) {
            // If system prefers dark mode or it is between 7pm and 6am
            setDarkmodeStatus(true);
        } else if (!prefers || (hours < 19 && hours > 6)) {
            setDarkmodeStatus(false);
        }
    }, [hours, prefers, setDarkmodeStatus]);

    // Toggle darkmode
    useEffect(() => {
        if (darkmodeStatus === true) {
            document.body.classList.add("darkmodeOn");
        } else {
            document.body.classList.remove("darkmodeOn");
        }
    }, [darkmodeStatus, setDarkmodeStatus]);

    return (
        <>
            <KeyboardEventHandler
                handleKeys={["alt + shift + D"]}
                onKeyEvent={(key, e) => setDarkmodeStatus(!darkmodeStatus)}
            />
            <div className="darkmodecontainer">
                <>
                    <Switch
                        onChange={toggleDarkmode}
                        checked={darkmodeStatus}
                        handleDiameter={28}
                        offColor="#fff"
                        onColor="#555"
                        offHandleColor="#ccc"
                        onHandleColor="#ccc"
                        uncheckedIcon={
                            <WiDaySunny
                                color="#000"
                                size="1.5rem"
                                style={{
                                    transform: "translate(0.3rem,0.3rem)",
                                }}
                            />
                        }
                        checkedIcon={
                            <WiMoonAltWaxingCrescent5
                                color="#999"
                                size="1.5rem"
                                style={{
                                    transform: "translate(0.3rem,0.3rem)",
                                }}
                            />
                        }
                        height={40}
                        width={70}
                        className="darkmodecontainer__darkmode subtle-shadow"
                    />
                </>
            </div>
            <div id="darkmodePreloader"></div>
            <div
                className="darkmodeTime pb-3 mb-5 text-left pl-5 pb-xl-2 "
                style={{ color: color }}
            >
                <p className="m-0" style={{ maxWidth: "auto" }}>
                    <span className="h3 font-weight-bold">
                        <Clock format={"HH:mm"} ticking={true} />
                    </span>
                </p>
                <p>
                    {darkmodeStatus ? "Darkmode: ON" : "Darkmode: OFF"}
                    <br />
                    {prefers ? "OS prefers darkmode" : ""}
                </p>
                <p className="pb-5">
                    <FiArrowDownLeft size="3rem" />
                </p>
            </div>
        </>
    );
};

export default Darkmode;
