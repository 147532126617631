import React from "react";
import { NavLink } from "react-router-dom";

// External components
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SVG from "react-inlinesvg";

// Styling
import "sass/components/_homeCarousel.scss";
import { IoMdArrowRoundForward } from "react-icons/io";

const HomeCarousel = ({
    className,
    xs,
    sm,
    md,
    lg,
    xl,
    itemClasses,
    imgClasses,
    projects,
    pauseOnHover,
}) => {
    const sortedProjects = projects
        .slice()
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    const darken = (item) => {
        if (item === null) {
            const r = item.bgColor.rgb.r;
            const g = item.bgColor.rgb.g;
            const b = item.bgColor.rgb.b;
            if (r + g + b > 300) {
                return "darken";
            }
        }
    };
    let responsive = {
        // Following Bootstrap media queries
        xs: {
            breakpoint: { min: 0, max: 576 },
            items: xs ? xs : 1.5,
        },
        sm: {
            breakpoint: { min: 576, max: 768 },
            items: sm ? sm : 2.5,
        },
        md: {
            breakpoint: { min: 768, max: 992 },
            items: md ? md : 2,
        },
        lg: {
            breakpoint: { min: 992, max: 1400 },
            items: lg ? lg : 2,
        },
        xl: {
            breakpoint: { min: 1400, max: 10000 },
            items: xl ? xl : 3,
        },
    };
    return (
        <>
            <Carousel
                responsive={responsive}
                swipeable
                draggable
                showDots={false}
                infinite={true}
                partialVisible={true}
                keyBoardControl={true}
                removeArrowOnDeviceType={["xs", "sm", "md", "lg", "xl"]}
                itemClass="user-select-none"
                autoPlay={pauseOnHover ? false : true}
                autoPlaySpeed={3000}
                // customTransition="transform 1.5s ease-in-out"
                // transitionDuration={1500}
                slidesToSlide={1}
                className={`${className} homeCarousel multiCarousel rounded-tl-3 rounded-bl-3`}
            >
                {sortedProjects.map((item, index) => (
                    <div
                        key={index + "div1"}
                        className={`${itemClasses} position-relative w-100`}
                    >
                        <NavLink to={`/portfolio/${item.slug.current}`}>
                            <h2
                                className="h5 multiCarousel__icon pb-2"
                                style={{
                                    color: item.textColor
                                        ? item.textColor.hex
                                        : "grey",
                                }}
                            >
                                <IoMdArrowRoundForward />
                            </h2>
                            {/* <h2
                                className="h5 multiCarousel__text w-100"
                                style={{
                                    color: item.textColor
                                        ? item.textColor.hex
                                        : "grey",
                                }}
                            >
                                {item.title}
                            </h2> */}
                            {(() => {
                                if (item.thumb.asset.extension === "svg") {
                                    return (
                                        <div
                                            className={`w-100 rounded-2 d-flex multiCarousel__item ${imgClasses} ${darken(
                                                item
                                            )}`}
                                            style={{
                                                background: item.bgColor
                                                    ? item.bgColor.hex
                                                    : "white",
                                                color: item.textColor
                                                    ? item.textColor.hex
                                                    : "black",
                                            }}
                                        >
                                            <SVG
                                                src={item.thumb.asset.url}
                                                style={Object.assign({
                                                    width: "70%",
                                                })}
                                                className="mx-auto"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <img
                                            src={
                                                item.thumb.asset.url +
                                                "?fm=jpg&q=65&sharpen=70&w=800"
                                            }
                                            alt=""
                                            draggable="false"
                                            className={`w-100 rounded-2 multiCarousel__item ${imgClasses} ${darken(
                                                item
                                            )}`}
                                        />
                                    );
                                }
                            })()}
                        </NavLink>
                    </div>
                ))}
            </Carousel>
        </>
    );
};

export default HomeCarousel;
