import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HamburgerMinus } from "react-animated-burgers";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Internal components
import Logo from "components/layout/Logo";
import { SocialSingle } from "components/social/Social";
import Back from "components/nav/Back";

// Icons
import { FaEye, FaMobileAlt } from "react-icons/fa";
import I from "components/layout/icons/IconContainer";
import { IoIosCloseCircleOutline } from "react-icons/io";
// import { MenuIcon } from "components/layout/icons/Icons";

const NavTop = ({ className, home, color }) => {
    const [menuStatus, setMenuStatus] = useState(false);
    const toggleMenu = () => setMenuStatus(!menuStatus);
    return (
        <>
            <Container
                className={`navTop pt-2 px-0 container-nav py-sm-4 p-0 pt-lg-5 px-md-5 ${className}`}
                id="top"
                fluid="xxl"
            >
                <Navbar expand="sm" className="p-0" id="navTop">
                    <Navbar.Toggle
                        onClick={() => toggleMenu()}
                        aria-controls="basic-navbar-nav"
                        className={`navTopBg border-0 p-0 m-0 rounded-0  ${
                            menuStatus ? "navTopBg-show" : ""
                            }`}
                    >
                        <Button
                            className="d-inline text-center navTop__close btn"
                            variant="transparent-grey"
                        >
                            <IoIosCloseCircleOutline size="3rem" />
                        </Button>
                    </Navbar.Toggle>
                    <div className="ml-3">
                        <Navbar.Brand>
                            <Navbar.Toggle
                                onClick={() => toggleMenu()}
                                aria-controls="basic-navbar-nav"
                                className="border-0 p-o m-0"
                            >
                                <NavLink
                                    to="/"
                                    className={`${!home ? "d-none" : ""}`}
                                >
                                    <Logo size="3rem" />
                                </NavLink>
                            </Navbar.Toggle>
                            <NavLink
                                to="/"
                                className="border-0 p-o m-0 d-none d-sm-block"
                            >
                                <Logo size="3rem" />
                            </NavLink>

                            {!home ? (
                                <Back className="d-sm-none backBtn--left" />
                            ) : null}
                        </Navbar.Brand>
                    </div>

                    <Navbar.Toggle
                        onClick={() => toggleMenu()}
                        aria-controls="basic-navbar-nav"
                        className="border-0 pr-3"
                    >
                        <HamburgerMinus
                            isActive={menuStatus}
                            className={`hamburger ${
                                menuStatus ? "hamburger--toggled" : ""
                                }`}
                        />
                    </Navbar.Toggle>
                    <Navbar.Collapse
                        id="navTopCollapse"
                        className={`${menuStatus ? "show" : "none"}`}
                    >
                        {/* {home ? (
                            <Nav className="ml-auto pt-3 pt-sm-0 text-right d-none d-sm-flex d-lg-none mr-md-5">
                                <Social color={color} />
                            </Nav>
                        ) : (
                            ""
                        )} */}

                        <Nav
                            className={`ml-auto pt-3 pt-sm-0 text-right d-none d-sm-flex ${
                                !home ? "d-sm-flex" : ""
                                }`}
                        >
                            {!home ? (
                                <>
                                    <Back className="d-sm-none backBtn--left" />
                                    <Back className="d-none d-sm-block position-relative" />
                                </>
                            ) : (
                                    <>
                                        {/* <NavLink to="/portfolio">
                                        <Button variant="primary">
                                            <span className="mr-2">
                                                <b>Åpne portefølje</b>
                                            </span>
                                            <I y="-0.1rem">
                                                <FaEye size="1.1rem" />
                                            </I>
                                        </Button>
                                    </NavLink> */}
                                    </>
                                )}
                            <NavLink to="/kontakt" className="mr-2">
                                <Button
                                    variant="outline-secondary"
                                    className="px-4 rounded-pill"
                                >
                                    <span className="mr-2">Ta kontakt</span>
                                    <I y="-0.1rem">
                                        <FaMobileAlt size="1.1rem" />
                                    </I>
                                </Button>
                            </NavLink>
                        </Nav>
                        <Navbar.Toggle
                            className="w-100 border-0 d-flex p-0 mt-1 pb-5 d-sm-none dark"
                            onClick={() => toggleMenu()}
                        >
                            <Nav className="ml-auto pt-5 pt-sm-0 w-100 ">
                                <Row className="pt-2 pt-sm-0 m-0">
                                    <Col xs={4} className="p-0">
                                        <SocialSingle
                                            className="w-100"
                                            type="github"
                                        />
                                    </Col>
                                    <Col xs={4} className="p-0">
                                        <SocialSingle
                                            className="w-100"
                                            type="behance"
                                        />
                                    </Col>
                                    <Col xs={4} className="p-0">
                                        <SocialSingle
                                            className="w-100"
                                            type="linkedin"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-5 pt-sm-0 m-0 vertical-divider">
                                    <Col xs={6} className="p-0">
                                        <NavLink
                                            to="/portfolio"
                                            className="p-0 m-0"
                                        >
                                            <Button
                                                className=" w-100 pr-3"
                                                variant="transparent"
                                            >
                                                <I x="-0.1rem" className="mr-3">
                                                    <FaEye />
                                                </I>
                                                Portfolio
                                            </Button>
                                        </NavLink>
                                    </Col>
                                    <Col xs={6} className="p-0">
                                        <NavLink
                                            to="/kontakt"
                                            className="p-0 m-0"
                                        >
                                            <Button
                                                className="w-100"
                                                variant="transparent"
                                            >
                                                Kontakt
                                                <I x="-0.1rem" className="ml-3">
                                                    <FaMobileAlt />
                                                </I>
                                            </Button>
                                        </NavLink>
                                    </Col>
                                </Row>
                            </Nav>
                        </Navbar.Toggle>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </>
    );
};

export default NavTop;
